import { IReactionDisposer, makeAutoObservable, reaction } from "mobx";
import { api } from "core/utility";
import BroadcastSocket from "common/broadcast/_stores/broadcast/BroadcastSocket";
import {
  IBroadcastSocketMethods,
  IC2CHash,
  IMemberDetail,
  IPrivateRequest,
  IQuickMessage,
} from "./interfaces";
import {
  BroadcastStreamState,
  BroadcastType,
  EnterShowSubType,
  IGameSettings,
  IShowType,
  MainTab,
  PrivateShowSelection,
  RecordingReasonsEnum,
  ShowStopReasons,
  ShowTab,
  WebRTCStatus,
} from "./enums";
import {
  BROADCAST_END_TIPPING_SHOW_EARLY_SHOW_STOP_WARNING_TIME_IN_MINUTES,
  BROADCAST_RECORDING_STOP_AFTER_N_SECONDS,
  BROADCAST_SHOW_TYPES_TO_RECORD_WHEN_AUTOMATIC_ENABLED,
  BROADCASTER_OFFLINE_ERROR_MESSAGE_ID,
  BROADCASTER_ONLINE_ERROR_MESSAGE_ID,
  InitialData,
  MOCKED_USER,
} from "./consts";
import { IChatNotification, IMember } from "../chat/interfaces";
import AuthStore from "core/stores/auth/AuthStore";
import { logger, uuid } from "library/core/utility";
import { snackbarStore } from "library/core/stores/snackbar/SnackbarStore";
import camsAudio from "library/core/utility/audio";
import { SnackbarVariants } from "library/core/stores/snackbar/enums";
import { BroadcastStreamType } from "common/broadcast/_stores/broadcast-stream/enums";
import {
  BroadcasterVariants,
  BroadcastShowStartStopEventMessage,
  BroadcastShowTrailDetail,
  BroadcastShowTypeTextAndIconDetail,
  BroadcastShowTypeTextAndIconDetails,
  GuestModel,
} from "common/broadcast/_stores/broadcast/types";
import { MessageDescriptor } from "react-intl";
import { broadcastStrategy } from "common/broadcast/_stores/BroadcastStrategy";
import { layoutStore } from "library/core/stores/layout/LayoutStore";
import { languageStore } from "library/core/stores/language/LanguageStore";
import {
  authStore,
  broadcastStreamStore,
  logToGraylog,
  nodeChatStore,
  pricingStore,
  profileStore,
} from "core/stores";
import { modalStore } from "library/core/stores/modal";
import { BROADCAST_MODAL_OBS_INVALID_SETTINGS_NAME } from "common/broadcast/_stores/broadcast-stream/consts";
import config, { enableFrequentSocketLogs } from "core/config";
import { NonManagerProfile } from "common/my-page/stores/profile/types";
import { BroadcastErrorAction } from "../broadcast-stream/types";
import { IChatTab, MemberActionEnum } from "../chat/enums";

const logPrefix = "[BroadcastStore]:";
const { intl } = languageStore!;

export default class BroadcastStore {
  broadcastSocket: BroadcastSocket | undefined = InitialData.broadcastSocket;

  onlineOfflineChangeReaction?: IReactionDisposer =
    InitialData.onlineOfflineChangeReaction;
  showTypeChangeReaction?: IReactionDisposer =
    InitialData.showTypeChangeReaction;
  interval?: NodeJS.Timeout = InitialData.interval;

  constructor() {
    makeAutoObservable(this);
  }

  webRTCStatus: WebRTCStatus = InitialData.webRTCStatus;
  selectedMemberId?: string = InitialData.selectedMemberId;
  selectedMember?: IMemberDetail = InitialData.selectedMember;
  members: IMember[] = InitialData.members;
  guests = InitialData.guests;
  quickMessages: IQuickMessage[] = InitialData.quickMessages;
  currentShowType: IShowType = InitialData.currentShowType;
  previousShowType: IShowType = InitialData.previousShowType;
  currentGameSettings: IGameSettings = InitialData.currentGameSettings;
  isVideoSettingsPopoverShown: boolean =
    InitialData.isVideoSettingsPopoverShown;
  isVideoSettingsOverlayShown: boolean =
    InitialData.isVideoSettingsOverlayShown;
  isAudioSettingsPopoverShown: boolean =
    InitialData.isAudioSettingsPopoverShown;
  isWheelOfFunSettingsPopoverShown: boolean =
    InitialData.isWheelOfFunSettingsPopoverShown;
  shouldShowEndTippingShowConfirmation: boolean =
    InitialData.showEndTippingShowConfirmation;
  shouldShowStartTippingShowConfirmation: boolean =
    InitialData.shouldShowStartTippingShowConfirmation;
  showTippingSum: number = InitialData.showTippingSum;

  c2cHash: IC2CHash = InitialData.c2cHash;

  currentShowTab: ShowTab = InitialData.currentShowTab;
  previousShowTab: ShowTab = InitialData.previousShowTab;
  currentMainTab: MainTab = InitialData.currentMainTab;
  previousMainTab: MainTab = InitialData.previousMainTab;

  privateShowChoice: PrivateShowSelection = InitialData.privateShowChoice;

  isPrivateConsentOpen: boolean = InitialData.isPrivateConsentOpen;
  currentPrivateRequest: IPrivateRequest | null =
    InitialData.currentPrivateRequest;
  currentPrivateRequestRemainingSeconds: number =
    InitialData.currentPrivateRequestRemainingSeconds;
  currentPrivateRequestCountdownInterval: any =
    InitialData.currentPrivateRequestCountdownInterval;
  currentPrivateRequestChatPrice: number =
    InitialData.currentPrivateRequestChatPrice;

  startShowRetry: number = InitialData.startShowRetry;
  startShowMaxRetry: number = InitialData.startShowMaxRetry;

  isBuzzPopUpOpen: boolean = InitialData.isBuzzPopUpOpen;
  isWofPopUpOpen: boolean = InitialData.isWofPopUpOpen;

  isRecording: boolean = InitialData.isRecording;
  isStartingRecording: boolean = InitialData.isStartingRecording;
  recordingReason: RecordingReasonsEnum | null = InitialData.recordingReason;
  recordingTimerInterval: number | undefined = undefined;
  recordingDurationInSeconds: number = 0;
  minimumRecordingDurationInSeconds: number =
    InitialData.minimumRecordingDurationInSeconds;
  countdownStarted: boolean = InitialData.countdownStarted;
  streamState: BroadcastStreamState = InitialData.streamState;
  isMakingStartShowTypeRequest: boolean =
    InitialData.isMakingStartShowTypeRequest;

  showTrail: Record<string, BroadcastShowTrailDetail> = {};
  timesChangedShowType: number = 0;

  endTippingShowEarlyShowStopWarningTimeInSeconds: number =
    BROADCAST_END_TIPPING_SHOW_EARLY_SHOW_STOP_WARNING_TIME_IN_MINUTES;

  storeInited: boolean = InitialData.storeInited;
  isAlreadyBroadcastingInAnotherWindow: boolean =
    InitialData.isAlreadyBroadcastingInAnotherWindow;
  currentShowSessionId: string | undefined = InitialData.currentShowSessionId;
  currentShowSessionStartTime: number | undefined =
    InitialData.currentShowSessionStartTime;

  broadcasterVariant: BroadcasterVariants = "default";
  isBroadcastingAlone: boolean | undefined = InitialData.isBroadcastingAlone;
  guestProfilesInCurrentSession: NonManagerProfile[] = [];
  isBroadcastingWithGuestsDataProcessing: boolean = false;
  isBroadcastingModeSelected: boolean = false;

  broadcastSessionId: string | undefined = InitialData.broadcastSessionId;

  isCountUpTimerOn: boolean = InitialData.isCountUpTimerOn;

  bountyOrderMember: string | undefined = InitialData.bountyOrderMember;
  fanClubOrderMember: string | undefined = InitialData.fanClubOrderMember;

  currentPPM_Message: BroadcastShowStartStopEventMessage | null = null;
  previousPPM_Message: BroadcastShowStartStopEventMessage | null = null;
  reconnectToastTO: any = null;

  log = (...params: any[]) => {
    logger.log(logPrefix, ...params);
  };

  resetStore = () => {
    this.log("resetStore started");
    this.broadcastSocket?.closeSocket();
    Object.assign(this, InitialData);
    this.log("resetStore finished");
  };

  setGuestProfilesInCurrentSession = (guestProfiles: any[]) => {
    this.guestProfilesInCurrentSession = guestProfiles;
  };

  removeGuestProfile = async (guest: GuestModel, refetch: boolean = true) => {
    await api.broadcastGuestModelConfirmation.delete(`${guest.id}`);
    if (refetch) {
      await this.fetchGuestProfilesInCurrentSession();
    }
  };

  fetchGuestProfilesInCurrentSession = async () => {
    try {
      this.setGuestProfilesInCurrentSession([]);
      const { data = [] } = await api.broadcastGuestModelConfirmation.get();
      this.setGuestProfilesInCurrentSession(
        data.map(item => ({
          name: item.guest_model_screen_name || item.guest_model,
          id: item.id,
          guest_id: item.guest_model,
        }))
      );
    } catch {
      this.setGuestProfilesInCurrentSession([]);
    }
  };

  get isInTippingShow() {
    return (
      this.currentShowType === IShowType.TIPPING ||
      this.currentShowType === IShowType.CURTAIN_DROPPED
    );
  }

  get isStartingOrStoppingStream() {
    return (
      this.streamState === BroadcastStreamState.startingStream ||
      this.streamState === BroadcastStreamState.stoppingStream
    );
  }

  get isStartingStream() {
    return this.streamState === BroadcastStreamState.startingStream;
  }

  get isStoppingStream() {
    return this.streamState === BroadcastStreamState.stoppingStream;
  }

  get isStartedOrStoppedStream() {
    return (
      this.streamState === BroadcastStreamState.started ||
      this.streamState === BroadcastStreamState.stopped
    );
  }

  setupReactions = () => {
    this.log("setupReactions started");
    this.disposeReactions();
    this.addBroadcastReactions();
    this.log("setupReactions finished");
  };

  disposeReactions = () => {
    this.log("disposeReactions started");
    this.disposeBroadcastReactions();
    this.log("disposeReactions finished");
  };

  addBroadcastReactions = () => {
    this.log("addBroadcastReactions started");
    this.showTypeChangeReaction = reaction(
      () => this.currentShowType,
      () => {
        if (broadcastStreamStore.streamType === BroadcastStreamType.OBS) {
          broadcastStreamStore.startOBSTimer();
        }
        this.log("addBroadcastReactions showTypeChangeReaction reacting");
        this.addShowTrail(this.currentShowType, this.previousShowType);
        this.handleRecordingStateWhenShowTypeChanges();
      }
    );
    this.onlineOfflineChangeReaction = reaction(
      () => layoutStore.isOffline,
      async () => {
        if (layoutStore.isOffline) {
          this.log(
            "onlineOfflineChangeReaction detected offline, disconnecting"
          );
          await broadcastStrategy.disconnect("offline");
        } else if (
          typeof broadcastStreamStore.broadcastError === "object" &&
          (broadcastStreamStore.broadcastError?.id ===
            BROADCASTER_OFFLINE_ERROR_MESSAGE_ID ||
            !broadcastStreamStore.broadcastError)
        ) {
          this.log("onlineOfflineChangeReaction detected online, connecting");
          broadcastStreamStore.setBroadcastError({
            id: BROADCASTER_ONLINE_ERROR_MESSAGE_ID,
            defaultMessage:
              "You are now online, setting up stream, please wait...",
          });
          await broadcastStrategy.connect();
          broadcastStreamStore.setBroadcastError(null);
        }
      }
    );
  };

  handleRecordingStateWhenShowTypeChanges = () => {
    if (!this.isRecording && this.isShowStarted) {
      const isAutomaticRecordingShowType =
        BROADCAST_SHOW_TYPES_TO_RECORD_WHEN_AUTOMATIC_ENABLED.includes(
          this.currentShowType
        );
      const isPrivateWithVoyeurOff =
        [IShowType.PRIVATE, IShowType.GROUP].includes(this.currentShowType) &&
        !pricingStore.modelProducts?.is_voyeur_allowed;
      const isRecordingAllPrivateShows =
        !!pricingStore?.modelProducts?.chat_settings
          ?.is_recording_all_private_shows;
      if (
        !isPrivateWithVoyeurOff &&
        isAutomaticRecordingShowType &&
        isRecordingAllPrivateShows
      ) {
        try {
          this.startRecording(RecordingReasonsEnum.AUTOMATIC);
        } catch (error) {}
      }
    } else {
      this.stopRecording(true);
    }
  };

  addShowTrail = (showType: IShowType, previousShowType: IShowType) => {
    this.timesChangedShowType = this.timesChangedShowType + 1;
    const currentShowStartedAt = new Date();
    const currentShowTypeKey = `${this.timesChangedShowType}-${showType}`;
    const previousShowTypeKey = `${
      this.timesChangedShowType - 1
    }-${previousShowType}`;
    this.showTrail[currentShowTypeKey] = {
      nextShowType: showType,
      previousShowType: previousShowType,
      startedAt: currentShowStartedAt,
    };

    if (this.timesChangedShowType > 1 && this.showTrail[previousShowTypeKey]) {
      const durationInSeconds =
        (currentShowStartedAt.getTime() -
          this.showTrail[previousShowTypeKey].startedAt.getTime()) /
        1000;
      this.showTrail[previousShowTypeKey] = {
        ...this.showTrail[previousShowTypeKey],
        endedAt: currentShowStartedAt,
        durationInSeconds: durationInSeconds,
      };
    }
  };

  get lastShowTrailDetail(): BroadcastShowTrailDetail | undefined {
    const currentShowTypeKey = `${this.timesChangedShowType}-${this.currentShowType}`;
    return this.showTrail[currentShowTypeKey];
  }

  setEndTippingShowEarlyShowStopWarningTimeInSeconds = (seconds: number) => {
    this.endTippingShowEarlyShowStopWarningTimeInSeconds = seconds;
  };

  disposeBroadcastReactions = () => {
    if (this.showTypeChangeReaction) {
      this.showTypeChangeReaction();
      this.showTypeChangeReaction = undefined;
    }
    if (this.onlineOfflineChangeReaction) {
      this.onlineOfflineChangeReaction();
      this.onlineOfflineChangeReaction = undefined;
    }
  };

  selectMemberProfile = (id: string) => {
    this.log("selectMemberProfile started");
    this.selectedMemberId = id;
  };

  setBroadcastingVariant = (variant: BroadcasterVariants) => {
    this.log("setBroadcastingVariant started");
    this.broadcasterVariant = variant;
    this.log("setBroadcastingVariant finished");
  };

  setIsBroadcastingAlone = async (isBroadcastingAlone: boolean) => {
    this.log("setIsBroadcastingAlone started");
    this.isBroadcastingAlone = isBroadcastingAlone;
    this.log("setIsBroadcastingAlone finished");
  };

  setIsBroadcastingModeSelected = async (
    isBroadcastingModeSelected: boolean
  ) => {
    if (this.isBroadcastingAlone && isBroadcastingModeSelected) {
      try {
        this.isBroadcastingWithGuestsDataProcessing = true;
        await Promise.all(
          this.guestProfilesInCurrentSession.map(guest =>
            this.removeGuestProfile(guest, false)
          )
        );
      } finally {
        this.isBroadcastingWithGuestsDataProcessing = false;
      }
    }
    this.isBroadcastingModeSelected = isBroadcastingModeSelected;
  };

  getMember = async (id: string) => {
    this.log("getMember started");
    let data;
    try {
      const response = await api.members.get(id);
      data = response.data;
      this.log("getMember success");
    } catch (error) {
      this.log("getMember failed, assigning mock user", error);
      data = MOCKED_USER;
    }
    data.pictures = Object.entries(data)
      .filter(
        ([key, value]) => key.indexOf("gallery_picture_") === 0 && !!value
      )
      .map(([, value]) => value);

    data.profile_picture =
      data.profile_picture || (data.pictures.length && data.pictures[0]);
    data.aboutShort =
      data.about.length > 100 ? `${data.about.slice(0, 100)}...` : data.about;
    //data.score = nodeChatStore.topAdmirersHash[id]?.rank || 100;
    this.selectedMember = data;
    this.log("getMember finished");
  };

  getQuickMessages = async () => {
    try {
      this.log("getQuickMessages started");
      const {
        data: { results },
      } = await api.savedMessages.get();
      this.quickMessages = results as IQuickMessage[];
      this.log("getQuickMessages success");
      return this.quickMessages;
    } catch (error) {
      this.log("getQuickMessages failed", error);
    } finally {
      this.log("getQuickMessages finished");
    }
  };

  postQuickMessage = async (message: string) => {
    try {
      this.log("postQuickMessage started");
      await api.savedMessages.post({ message });
      return this.getQuickMessages();
    } catch (error) {
      this.log("postQuickMessage failed", error);
    } finally {
      this.log("postQuickMessage finished");
    }
  };

  putQuickMessage = async (quickMessage: IQuickMessage) => {
    try {
      this.log("putQuickMessage started");
      const { message } = quickMessage;
      await api.savedMessages.put({ message }, `${quickMessage.id}/`);
      this.log("putQuickMessage success");
    } catch (error) {
      this.log("putQuickMessage failed", error);
    } finally {
      this.log("putQuickMessage finished");
    }
  };

  deleteQuickMessage = async (quickMessageId: string) => {
    try {
      this.log("deleteQuickMessage started");
      await api.savedMessages.delete(quickMessageId);
      const filtered = this.quickMessages.filter(x => x.id !== quickMessageId);
      this.quickMessages = [...filtered];
      this.log("deleteQuickMessage success");
    } catch (error) {
      this.log("deleteQuickMessage failed", error);
    } finally {
      this.log("deleteQuickMessage finished");
    }
  };

  checkIfAlreadyBroadcastingInAnotherTabOrWindow =
    async (): Promise<boolean> => {
      this.log("checkIfAlreadyBroadcastingInAnotherTabOrWindow started");
      try {
        const { data } = await api.broadcastStats.get(`/${this.channelName}`);
        if (!data) {
          this.setIsAlreadyBroadcasting(false);
          return this.isAlreadyBroadcastingInAnotherWindow;
        }
        if (data.stats.applicationName === config.broadcastApp) {
          broadcastStreamStore.obsStreamConnected = true;
        }
        this.setIsAlreadyBroadcasting(false);
        // when stats are defined, it means model already has
        // either obs connected (but may not have started the show) or is already broadcasting with webrtc

        if (
          this.streamState === BroadcastStreamState.stopped &&
          !broadcastStreamStore.isJustStopped &&
          !modalStore.hasModalActiveWithName(
            BROADCAST_MODAL_OBS_INVALID_SETTINGS_NAME
          ) &&
          data !== undefined &&
          (((broadcastStreamStore.streamType === null ||
            broadcastStreamStore.streamType === BroadcastStreamType.OBS) &&
            !broadcastStreamStore.obsStreamConnected) ||
            broadcastStreamStore.streamType === BroadcastStreamType.WEBRTC)
        ) {
          this.log(
            "checkIfAlreadyBroadcastingInAnotherTabOrWindow found that another tab/window is active"
          );

          if (
            data?.stats?.properties?.source === "OBS" &&
            data?.stats?.properties?.token
          ) {
            this.setIsAlreadyBroadcasting(true);
          } else {
            this.setIsAlreadyBroadcasting(true);
          }
        }
        this.log("checkIfAlreadyBroadcastingInAnotherTabOrWindow finished");
        return this.isAlreadyBroadcastingInAnotherWindow;
      } catch (e) {
        this.setIsAlreadyBroadcasting(false);
        this.log(
          "checkIfAlreadyBroadcastingInAnotherTabOrWindow finished, Error",
          e
        );
        return this.isAlreadyBroadcastingInAnotherWindow;
      }
    };

  setIsAlreadyBroadcasting = (isBroadcasting: boolean) => {
    this.log("setIsAlreadyBroadcasting started");
    this.isAlreadyBroadcastingInAnotherWindow = isBroadcasting;
    this.log("setIsAlreadyBroadcasting finished");
  };

  terminateActiveBroadcastingSession = async () => {
    this.log("terminateActiveBroadcastingSession started");
    try {
      const { data } = await api.broadcastSessions.delete(
        `close/${this.channelName}`
      );
      this.log("terminateActiveBroadcastingSession finished, Success", data);
    } catch (e) {
      this.log("terminateActiveBroadcastingSession finished, Error", e);
    }
  };

  init = async () => {
    this.log("init started");
    if (this.storeInited) {
      this.log("init found store is initialized, stopping");
      return;
    }
    Object.assign(this, InitialData);
    try {
      const accessToken = AuthStore.getAccessToken();
      if (!!accessToken && accessToken !== "") {
        const { data } = await api.broadcastConstants.get();
        this.minimumRecordingDurationInSeconds =
          data["min_automatic_recorded_show_duration_in_seconds"] ||
          this.minimumRecordingDurationInSeconds;

        this.broadcastSocket = new BroadcastSocket();
        this.log("listenToBroadcasting found token, connecting to broadcast");

        this.broadcastSocket?.connect(
          accessToken,
          this.handleAuthFailed,
          this.handleConnected,
          this.handleDisconnected,
          this.onMiddlewareTriggered,
          this.broadcastSocketMethods
        );

        this.setupReactions();
        await this.checkIfAlreadyBroadcastingInAnotherTabOrWindow();

        if (broadcastStreamStore.obsStreamConnected) {
          broadcastStreamStore.setStreamType(BroadcastStreamType.OBS);
        }
        this.storeInited = true;
        broadcastStreamStore.setBroadcastError(null);
      } else {
        this.log(
          "init did not find access token, getting a new access token and retrying"
        );
        await this.handleAuthFailed();
        await this.init();
      }
    } catch (error) {
      this.log("init failed", error);
    }
    this.log("init finished");
  };

  handleConnected = async () => {
    this.checkIfModelIsReallyOnline();
  };

  checkIfModelIsReallyOnline = async () => {
    if (this.currentShowType !== IShowType.NONE) {
      try {
        await api.broadcastShowSessions.get(`channel/${this.channelName}/`);
      } catch {
        await broadcastStrategy.disconnect();
      }
    }
  };

  handleDisconnected = async () => {};

  handleSocketError = () => {};

  handleAuthFailed = async () => {
    const authResponse =
      await authStore.getAuthResponseFromBackendViaRefreshToken();
    return authResponse.access;
  };

  get broadcastSocketMethods(): IBroadcastSocketMethods {
    return {
      broadcast_start: this.handleBroadcastStart,
      broadcast_stop: this.handleBroadcastStop,
      show_start: this.handleShowStart,
      show_stop: this.handleShowStop,
      view_start: this.handleViewStart,
      view_stop: this.handleViewStop,
      view_interrupted: this.handleViewInterrupted,
      view_resumed: this.handleViewResumed,
      tipping_session: this.handleTipped,
      c2c_show_start: this.handleC2CStart,
      c2c_show_stop: this.handleC2CStop,
      stream_stats: broadcastStreamStore.handleStreamStatsReceived,
      private_show_request: this.handlePrivateShowRequest,
      model_private_blocked: this.handleModelPrivateBlocked,
      subscribed_members: this.handleSubscribedMembers,
      member_joined: this.handleMemberJoined,
      member_left: this.handleMemberLeft,
      ppm_call: this.handlePPMCall,
    };
  }

  handleViewResumed = (data: any) => {
    this.log("handleViewResumed started");

    const handle = `${data.userName}@cams`.toLowerCase();

    if (nodeChatStore.allMemberHash[handle]) {
      nodeChatStore.allMemberHash[handle].isReconnecting = false;
    }

    // const event = {
    //   id: uuid.getNew(),
    //   created: new Date(),
    //   fixedContentKey: `notificationMessage.viewResumed`,
    //   fixedContentParams: {
    //     member: `${data.userName}`,
    //   },
    // } as IChatNotification;
    // if(this.isInPaidShow){
    //   nodeChatStore.addNotification(event);
    // }
    this.log("handleViewResumed finished");
  };

  handleViewInterrupted = (data: any) => {
    this.log("handleViewInterrupted started");

    const handle = `${data.userName}@cams`.toLowerCase();

    if (nodeChatStore.allMemberHash[handle]) {
      nodeChatStore.allMemberHash[handle].isReconnecting = true;
    }

    // const event = {
    //   id: uuid.getNew(),
    //   created: new Date(),
    //   fixedContentKey: `notificationMessage.viewInterrupted`,
    //   fixedContentParams: {
    //     member: `${data.userName}`,
    //   },
    // } as IChatNotification;
    // if(this.isInPaidShow){
    //   nodeChatStore.addNotification(event);
    // }

    this.log("handleViewInterrupted finished");
  };

  updatePPM_Message(newPPM_Message: BroadcastShowStartStopEventMessage) {
    if (this.currentPPM_Message) {
      this.previousPPM_Message = this.currentPPM_Message;
    }
    this.currentPPM_Message = newPPM_Message;
  }

  get fetchTimeDifference(): number | null {
    if (this.currentPPM_Message && this.previousPPM_Message) {
      return Math.round(
        this!.currentPPM_Message!.data!.fetchTime! -
          this!.previousPPM_Message!.data!.fetchTime!
      );
    }
    return 0;
  }

  handlePPMCall = (msg: any) => {
    setTimeout(() => {
      let handle: string;
      if (msg.data.payload.showType === IShowType.C2C) {
        handle = msg.data.payload.channelName.split("+")[0];
      } else {
        handle = msg.data.payload.userName;
      }
      handle = `${handle.toLowerCase()}@cams`;

      if (nodeChatStore.allMemberHash[handle]) {
        nodeChatStore.allMemberHash[handle].balance = msg.data.balance;
      }

      this.updatePPM_Message(msg);

      nodeChatStore.addTimeRemainingMessageToChat(handle, msg);
      nodeChatStore.fetchTopAdmirers();
    }, 3000);
  };

  handleSubscribedMembers = (data: any) => {
    this.log("handleSubscribedMembers", data);
    if (data && data.length > 0) {
      data.forEach((member: any) => {
        nodeChatStore.handleMemberJoinedBroadcast(member);
      });
    }
  };

  handleMemberJoined = (data: any) => {
    this.log("handleMemberJoined", data);
    nodeChatStore.handleMemberJoinedBroadcast(data);
  };

  handleMemberLeft = (data: any) => {
    this.log("handleMemberLeft", data);
    nodeChatStore.handleMemberLeftBroadcast(data);
  };

  onMiddlewareTriggered = (eventName: string, args: any) => {
    if (enableFrequentSocketLogs) {
      this.log(
        "onMiddlewareTriggered started with eventName",
        eventName,
        "and args",
        args
      );
    }
    const eventNames: string[] = [];
    const logExceptionEvents: string[] = ["ping", "pong"];
    if (eventName) {
      eventNames.push(eventName);
      if (!logExceptionEvents.includes(eventName)) {
        logToGraylog(
          logPrefix,
          `middleware received event with event name: ${eventName} and event data:`,
          args
        );
      }
    }
    if (enableFrequentSocketLogs) {
      this.log("onMiddlewareTriggered finished");
    }
  };

  setStreamState = (state: BroadcastStreamState) => {
    this.log("setStreamState started", state);
    this.streamState = state;
    this.log("setStreamState finished");
  };

  startStreaming = async () => {
    this.log("startStream started");
    this.setStreamState(BroadcastStreamState.startingStream);
    await nodeChatStore.openChatSocket();
    setTimeout(async () => {
      if (broadcastStreamStore.streamType === BroadcastStreamType.OBS) {
        await this.startShowType();
      } else {
        await this.startWebRTCShow();
      }
      try {
        nodeChatStore.checkAndInitLovense();
      } catch (e) {}
      this.log("startStream finished");
    }, 2000);
  };

  get channelName() {
    const profile = profileStore.modelProfile;
    const _channelName = (profile?.screen_name || "").toLowerCase();
    return _channelName;
  }

  startShowType = async (showType?: IShowType): Promise<void> => {
    this.log("startShowType started");
    this.setStreamState(BroadcastStreamState.startingStream);
    let isSuccess: boolean = false;
    let is417Or429Error: boolean = false;
    let error: any = undefined;
    let hasReachedRetryLimit: boolean = false;
    let canStartStream: boolean = true;

    if (
      this.streamState !== BroadcastStreamState.stoppingStream &&
      !this.isMakingStartShowTypeRequest
    ) {
      if (broadcastStreamStore.streamType === BroadcastStreamType.OBS) {
        try {
          if (this.broadcasterVariant === "member-verification") {
            canStartStream = await this.checkVideoAfterStreamStart();
          } else {
            const { data } = await api.broadcastStats.get(
              `${this.channelName}/`
            );
            const areOBSSettingsValid =
              await broadcastStreamStore.validateStreamStats(
                data?.stats?.streams
              );
            if (!areOBSSettingsValid) {
              canStartStream = false;
            }
          }
        } catch (error) {
          // ignore error, when it errors, its 404, meaning no one is broadcasting with OBS
        }
      }

      if (!canStartStream) {
        this.setStreamState(BroadcastStreamState.stopped);
        return;
      }

      let startingShowType: IShowType;
      if (showType) {
        startingShowType = showType;
      } else if (this.currentShowTab === ShowTab.FREE) {
        startingShowType = IShowType.FREE;
      } else if (this.currentShowTab === ShowTab.NUDE) {
        startingShowType = IShowType.NUDE;
      } else if (this.currentShowTab === ShowTab.TIPPING) {
        startingShowType = IShowType.TIPPING;
      } else {
        startingShowType = IShowType.FREE;
      }

      if (
        this.streamState === BroadcastStreamState.stopped &&
        startingShowType === IShowType.NUDE
      ) {
        this.log(
          "startShowType starting nude show, going into free show first"
        );
        await this.startShowType(IShowType.FREE);
        this.log(
          "startShowType started free show before nude, continuing start nude show"
        );
      }

      try {
        this.log(
          "startShowType starting streamingType",
          broadcastStreamStore.streamType
        );

        await this.startShowRequest({
          startingShowType,
          channelName: this.channelName,
        });

        window.onbeforeunload = function () {
          return "Leaving this page will reset the show";
        };
        isSuccess = true;
        this.log("startShowType success");
      } catch (err) {
        isSuccess = false;
        this.log("startShowType failed", err);
        if (
          err?.response?.status === 429 ||
          (err?.response?.status === 417 &&
            broadcastStreamStore.streamType === BroadcastStreamType.WEBRTC)
        ) {
          is417Or429Error = true;
        }

        error = err || true;
      } finally {
        if (is417Or429Error) {
          this.startShowRetry = 0;
          let retryInterval;
          retryInterval = setInterval(async () => {
            this.log("startShowType retry interval tick");
            if (isSuccess || this.startShowRetry > this.startShowMaxRetry) {
              clearInterval(retryInterval);
              retryInterval = undefined;
              hasReachedRetryLimit = true;
              if (isSuccess) {
                await this.onStartShowTypeEnd(isSuccess, startingShowType);
              }
              this.onIsRetryingChanged(false);
            } else if (
              !this.isMakingStartShowTypeRequest &&
              !hasReachedRetryLimit
            ) {
              this.onIsRetryingChanged(true);
              try {
                this.startShowRetry += 1;
                this.log(
                  `startShowType retrying because response status is 417 or 429, retry interval ${this.startShowRetry}`
                );
                await this.startShowRequest({
                  startingShowType,
                  channelName: this.channelName,
                });
                isSuccess = true;
                clearInterval(retryInterval);
                retryInterval = undefined;
                await this.onStartShowTypeEnd(isSuccess, startingShowType);
              } catch (error) {
                isSuccess = false;
                this.log(
                  "startShowType retry failed",
                  this.startShowRetry,
                  error
                );
                this.onIsRetryingChanged(false);
              }
            } else if (!isSuccess && hasReachedRetryLimit) {
              this.log(
                "startShowType retry not successful, reached retry limit"
              );
              await this.onStartShowTypeFailed(error);
              await this.onStartShowTypeEnd(isSuccess, startingShowType);
              this.onIsRetryingChanged(false);
            }
          }, 2000);
        } else {
          this.log("startShowType finally started");
          if (error && !isSuccess) {
            await this.onStartShowTypeFailed(error);
          }
          await this.onStartShowTypeEnd(isSuccess, startingShowType);
        }
        this.onIsRetryingChanged(false);
      }
    }
    this.log("startShowType finished, isSuccess", isSuccess);
  };

  onStartShowTypeFailed = async (err: any) => {
    this.log("startShowType failed", err);
    broadcastStreamStore.setBroadcastError({
      id: "broadcast-error.general-error",
      defaultMessage: "There seems to be something wrong. Please try again",
    });
    if (broadcastStreamStore.isStreamingWebRTC) {
      await broadcastStreamStore.stopWebRTCBroadcast();
    } else {
      await broadcastStreamStore.setStreamType(null);
    }
  };

  checkVideoAfterStreamStart = async (attemptsRemaining = 15) => {
    while (attemptsRemaining > 0) {
      try {
        const { data } = await api.broadcastStats.get(`${this.channelName}/`);
        if (data?.stats?.streams && data?.stats?.streams.length > 0) {
          const stream = data?.stats?.streams?.find(
            s => s.name === this.channelName
          );

          if (stream?.videoInfo !== null) {
            const areOBSSettingsValid =
              await broadcastStreamStore.validateStreamStats(
                data?.stats?.streams
              );

            if (areOBSSettingsValid) {
              return true;
            }
          }
        }
      } catch {
        // ignore error, when it errors, its 404, meaning no one is broadcasting with OBS
      }

      attemptsRemaining--;
      if (attemptsRemaining > 0) {
        await new Promise(resolve => setTimeout(resolve, 2000));
      }
    }

    // If we've exhausted all attempts, add the notification
    nodeChatStore.addNotification({
      id: uuid.getNew(),
      created: new Date(),
      content: "No Video detected, please check your camera settings.",
      fixedContentKey: `notificationMessage.noVideo`,
    } as IChatNotification);

    return false;
  };

  checkAudioInfoAfterStreamStart = () => {
    setTimeout(async () => {
      if (this.streamState === BroadcastStreamState.started) {
        const { data } = await api.broadcastStats.get(`${this.channelName}/`);
        if (data?.stats?.streams && data?.stats?.streams.length > 0) {
          const stream = data?.stats?.streams.filter(
            s => s.name === this.channelName
          );
          if (stream && stream[0]) {
            if (stream[0].audioInfo === null) {
              nodeChatStore.addNotification({
                id: uuid.getNew(),
                created: new Date(),
                content:
                  "No Audio detected, please check your microphone settings.",
                fixedContentKey: `notificationMessage.noAudio`,
              } as IChatNotification);
            }
          }
        }
      }
    }, 30000);
  };

  onStartShowTypeEnd = async (
    isSuccess: boolean,
    startingShowType: IShowType
  ) => {
    if (isSuccess) {
      this.setStreamState(BroadcastStreamState.started);
      this.setCurrentShowType(startingShowType);
      nodeChatStore.setChatType(startingShowType);
      nodeChatStore.handleNotifications(
        {
          startTime: new Date(),
        },
        BroadcastType.BroadcastStart,
        "broadcastStarted"
      );

      let activeShowTab: ShowTab;
      if (
        startingShowType === IShowType.TIPPING ||
        startingShowType === IShowType.CURTAIN_DROPPED
      ) {
        activeShowTab = ShowTab.TIPPING;
        if (startingShowType === IShowType.TIPPING) {
          nodeChatStore.initTippingChat();
        }
      } else if (startingShowType === IShowType.NUDE) {
        activeShowTab = ShowTab.NUDE;
      } else {
        activeShowTab = ShowTab.FREE;
      }
      this.setCurrentShowTab(activeShowTab);

      if (startingShowType !== IShowType.TIPPING) {
        this.setCurrentMainTab(MainTab.VIEWERS);
      }

      if (broadcastStreamStore.streamType === BroadcastStreamType.OBS) {
        broadcastStreamStore.startOBSTimer();
      }
      this.checkAudioInfoAfterStreamStart();
    } else {
      this.setStreamState(BroadcastStreamState.stopped);
    }
  };

  startShowRequest = async ({ startingShowType, channelName }) => {
    this.log("startShowRequest started");
    try {
      this.isMakingStartShowTypeRequest = true;
      await api.broadcastShows.post(
        {
          publishToken: broadcastStreamStore.publishToken,
          targetShowType: startingShowType,
          expectVideoStatsAvailable:
            this.startShowRetry < this.startShowMaxRetry,
        },
        `${channelName}/`
      );
      this.log("startShowRequest success");
    } catch (err) {
      this.log("startShowRequest failed", err);
      throw err;
    } finally {
      this.isMakingStartShowTypeRequest = false;
    }
    this.log("startShowRequest finished");
  };

  tryStartGoalDown = async () => {
    const current = this.showTippingSum || 0;
    const goal =
      pricingStore.modelProducts?.chat_settings?.tipping?.["goal"] || 0;
    const isGoalMet = goal !== 0 && current >= goal;

    if (!isGoalMet) {
      this.setShouldShowStartTippingShowConfirmation(true);
    } else {
      this.setShouldShowStartTippingShowConfirmation(false);
      this.startGoalDown();
    }
  };

  startGoalDown = async () => {
    this.log("startGoalDown started");
    const channelName = (
      profileStore.modelProfile?.screen_name || ""
    ).toLowerCase();
    try {
      await api.curtainDown.post(
        {
          channelName,
          participantUserSiteIds: [],
        },
        `${channelName}/`
      );
      this.log("startGoalDown success");
    } catch (err) {
      this.onStartShowTypeFailed(err);
      this.log("startGoalDown failed", err);
    } finally {
      this.log("startGoalDown finished");
    }
  };

  setShouldShowStartTippingShowConfirmation = confirmation => {
    this.shouldShowStartTippingShowConfirmation = confirmation;
  };

  setShouldShowEndTippingShowConfirmation = confirmation => {
    this.log("setShowEndTippingShowConfirmation started");
    this.shouldShowEndTippingShowConfirmation = confirmation;
    this.log(
      "setShowEndTippingShowConfirmation finished, confirmation:",
      confirmation
    );
  };

  get isShowStarted() {
    const _isShowStarted = this.currentShowType !== IShowType.NONE;
    this.log("isShowStarted returning", _isShowStarted);
    return _isShowStarted;
  }

  get isInPaidShow() {
    const _isInPaidShow =
      this.isShowStarted && this.paidShowTypes.includes(this.currentShowType);
    this.log("isInPaidShow returning", _isInPaidShow);
    return _isInPaidShow;
  }

  get isInAdminShow() {
    const _isInAdminShow =
      this.isShowStarted && this.currentShowType === IShowType.ADMIN;
    this.log("isInAdminShow returning", _isInAdminShow);
    return _isInAdminShow;
  }

  get isInPrivateOrAdminShow() {
    const _isInPrivateOrAdminShow =
      this.isShowStarted &&
      (this.currentShowType === IShowType.PRIVATE ||
        this.currentShowType === IShowType.ADMIN);
    this.log("isInPrivateOrAdminShow returning", _isInPrivateOrAdminShow);
    return _isInPrivateOrAdminShow;
  }

  setCurrentGameSettings = (gameSettings: IGameSettings) => {
    this.log("setCurrentGameSettings started");
    this.currentGameSettings = gameSettings;
    this.log("setCurrentGameSettings finished", gameSettings);
  };

  stopStreaming = async (
    stopMessage?: MessageDescriptor,
    stopAction?: BroadcastErrorAction
  ) => {
    this.log("stopStreaming started");
    let isSuccess: boolean = false;
    try {
      if (this.streamState === BroadcastStreamState.started) {
        this.setStreamState(BroadcastStreamState.stoppingStream);
        // this is not a real error, we just use the overlay to communicate
        broadcastStreamStore.setBroadcastError(
          stopMessage || {
            id: "broadcast-error.stopping-stream",
            defaultMessage: "Stopping stream, please wait...",
          },
          stopAction
        );
        const profile = profileStore.modelProfile;
        const channelName = (profile?.screen_name || "").toLowerCase();
        if (this.isRecording) {
          try {
            await this.stopRecording();
          } catch (error) {
            this.log("stopStreaming stopRecording failed", error);
          }
        }

        if (!layoutStore.isOffline) {
          try {
            await api.broadcastShows.post(
              {
                publishToken: broadcastStreamStore.publishToken,
                targetShowType: IShowType.NONE,
              },
              `${channelName}/`
            );
          } catch (error) {}
        } else {
        }
      }
      await nodeChatStore.closeChatSocket();
      // the below stopWebRTCBroadcast and stopOBSBroadcast need to be after the above POST with IShowType.NONE
      //https://jira.friendfinderinc.com/browse/CAMSRBT-5046
      if (broadcastStreamStore.streamType === BroadcastStreamType.WEBRTC) {
        await broadcastStreamStore.stopWebRTCBroadcast();
      } else if (broadcastStreamStore.streamType === BroadcastStreamType.OBS) {
        await broadcastStreamStore.stopOBSBroadcast();
      }
      isSuccess = true;
      this.log("stopStreaming success");
    } catch (error) {
      isSuccess = false;
      this.log("stopStreaming failed", error);
    } finally {
      if (isSuccess) {
        this.setStreamState(BroadcastStreamState.stopped);
      }
      window.onbeforeunload = () => {};
      this.log("stopStreaming finished");
    }
  };

  handleBroadcastStart = (_message, properties) => {
    this.log("handleBroadcastStart started");
    this.broadcastSessionId = _message?.sessionId;
    if (properties?.source === BroadcastStreamType.OBS) {
      broadcastStreamStore.obsStreamConnected = true;
      broadcastStreamStore.setStreamType(BroadcastStreamType.OBS);
    }
    this.log("handleBroadcastStart finished");
  };

  handleBroadcastStop = async (message, properties) => {
    this.log("handleBroadcastStop started", message, properties);
    this.setIsAlreadyBroadcasting(false);
    if (this.streamState === BroadcastStreamState.started) {
      this.isRecording = false;
    }
    if (properties?.source === BroadcastStreamType.OBS) {
      broadcastStreamStore.obsStreamConnected = false;
    }
    nodeChatStore.cleanupHashes();
    this.log("handleBroadcastStop finished");
  };

  getShowTypePrettyName = (
    showType: IShowType
  ): BroadcastShowTypeTextAndIconDetail => {
    const showTypeToPrettyName: Partial<BroadcastShowTypeTextAndIconDetails> = {
      FREE: {
        badgeText: languageStore.intl.formatMessage({
          id: "showTypes2.free",
          defaultMessage: "FREE",
        }),
        chatText: "FREE",
        iconColor: "green",
      },
      PRIVATE: {
        badgeText: languageStore.intl.formatMessage({
          id: "showTypes2.private",
          defaultMessage: "PRIVATE",
        }),
        chatText: "PRIVATE",
        iconColor: "red",
      },
      NUDE: {
        badgeText: languageStore.intl.formatMessage({
          id: "showTypes2.nude",
          defaultMessage: "NUDE",
        }),
        chatText: "NUDE",
        iconColor: "yellow",
      },
      GOAL_UP: {
        badgeText: languageStore.intl.formatMessage({
          id: "showTypes2.visible",
          defaultMessage: "VISIBLE",
        }),
        chatText: "VISIBLE GOAL",
        iconColor: "border-blue",
      },
      GOAL_DOWN: {
        badgeText: languageStore.intl.formatMessage({
          id: "showTypes2.hidden",
          defaultMessage: "HIDDEN",
        }),
        chatText: "HIDDEN GOAL",
        iconColor: "blue",
      },
      GROUP: {
        badgeText: languageStore.intl.formatMessage({
          id: "showTypes2.party",
          defaultMessage: "PARTY",
        }),
        chatText: "PARTY",
        iconColor: "purple",
      },
      ADMIN: {
        badgeText: languageStore.intl.formatMessage({
          id: "showTypes2.admin",
          defaultMessage: "ADMIN",
        }),
        chatText: "ADMIN",
        iconColor: "red",
      },
      VERIFIER: {
        badgeText: languageStore.intl.formatMessage({
          id: "showTypes2.verifier",
          defaultMessage: "VERIFIER",
        }),
        chatText: "VERIFIER",
        iconColor: "red",
      },
    };
    return showTypeToPrettyName[showType];
  };

  get paidShowTypes() {
    return [
      IShowType.PRIVATE,
      IShowType.GROUP,
      IShowType.NUDE,
      IShowType.CURTAIN_DROPPED,
      IShowType.ADMIN,
      IShowType.VERIFIER,
    ];
  }

  setCurrentShowSessionId = (id?: string) => {
    this.log("setCurrentShowSessionId started", id);
    this.currentShowSessionId = id;
    this.log("setCurrentShowSessionId finished");
  };

  setCurrentShowSessionStartTime = (time?: number) => {
    this.log("setCurrentShowSessionStartTime started", time);
    this.currentShowSessionStartTime = time;
    this.log("setCurrentShowSessionStartTime finished");
  };

  handleShowStart = async (
    message: BroadcastShowStartStopEventMessage,
    properties
  ) => {
    this.log(
      "handleShowStart started",
      message,
      properties,
      message.showType === IShowType.CURTAIN_DROPPED
    );
    broadcastStreamStore.videoQualityMarker = properties.videoQualityMark;
    this.setCurrentShowSessionId(message.sessionId);
    this.setCurrentShowSessionStartTime(message.startTime);
    this.setCurrentShowType(message.showType);

    this.isCountUpTimerOn = false;

    setTimeout(() => {
      this.isCountUpTimerOn = true;
    }, 500);

    nodeChatStore.setChatType(message.showType);

    if (message.showType === IShowType.CURTAIN_DROPPED) {
      nodeChatStore.setCurtainDown(true);
    }

    if (this.streamState === BroadcastStreamState.stopped) {
      this.setIsAlreadyBroadcasting(true);
    }

    if (
      this.currentShowType === IShowType.PRIVATE ||
      this.currentShowType === IShowType.GROUP
    ) {
      camsAudio.playStartPrivateChat();
    }

    if (message.showType == IShowType.ADMIN) {
      camsAudio.playStartPrivateChat();
      nodeChatStore.addNotification({
        id: uuid.getNew(),
        created: new Date(),
        content: "Admin {member} has started a private chat",
        fixedContentKey: `notificationMessage.adminShowStarted`,
        fixedContentParams: {
          member: properties?.requesterUserName || "",
        },
      } as IChatNotification);
      return;
    }

    if (message.showType == IShowType.VERIFIER) {
      camsAudio.playStartPrivateChat();
      nodeChatStore.addNotification({
        id: uuid.getNew(),
        created: new Date(),
        content: "{member} has started a verifier chat",
        fixedContentKey: `notificationMessage.verifierShowStarted`,
        fixedContentParams: {
          member: properties?.requesterUserName || "",
        },
      } as IChatNotification);
      return;
    }

    if (this.getShowTypePrettyName(message.showType)) {
      nodeChatStore.handleNotifications(
        message,
        BroadcastType.ShowStart,
        "showStarted",
        {
          showType: this.getShowTypePrettyName(message.showType).badgeText,
        }
      );
    }

    if (this.paidShowTypes.includes(message.showType)) {
      this.setCurrentShowType(message.showType);
      if (nodeChatStore.activeChatTab !== IChatTab.PUBLIC_OR_PRIVATE) {
        nodeChatStore.setActiveChatTab(IChatTab.PUBLIC_OR_PRIVATE);
      }
    } else if (
      message.showType === IShowType.PAUSED &&
      this.broadcasterVariant !== "member-verification"
    ) {
      this.setCurrentShowType(IShowType.PAUSED);
      nodeChatStore.addNotification({
        id: uuid.getNew(),
        created: new Date(),
        fixedContentKey: intl.formatMessage({
          id: `notificationMessage.showPaused`,
          defaultMessage:
            "Your show has ended! You have 30 seconds to get dressed before you get back to Free chat.",
        }),
      } as IChatNotification);
    }

    if (message.showType === IShowType.TIPPING) {
      this.resetShowTippingSum();
      //await this.getTippingShowData();
    }
    nodeChatStore.setResendUserList();
    this.log("handleShowStart finished");
  };

  handleShowStop = async (
    message: BroadcastShowStartStopEventMessage,
    _,
    reason
  ) => {
    this.log("handleShowStop started", message);
    // on BO, events are sync so they may not be received in correct order
    // an example is that start in free, switch to goal, then switch to free
    // SHOW_START is received for starting free but then a SHOW_STOP is received for the goal show
    // try to queue up unordered events
    let shouldIgnoreShowStop: boolean = false;

    if (
      this.currentShowSessionId !== message.sessionId &&
      this.currentShowSessionStartTime &&
      message?.endTime
    ) {
      if (message.endTime < this.currentShowSessionStartTime) {
        this.log(
          "handleShowStop found incorrectly ordered event, ignoring event. this occurred because of async code, not a big deal."
        );
        shouldIgnoreShowStop = true;
      } else if (message.endTime > this.currentShowSessionStartTime) {
        this.log(
          "handleShowStop found incorrectly ordered event, this is an error and is being logged"
        );
        logToGraylog(logPrefix, "Incorrect SHOW STOP timestamps", {
          currentShowSessionStartTime: this.currentShowSessionStartTime,
          eventShowStopEndTime: message.endTime,
        });
      }
    }
    if (!shouldIgnoreShowStop) {
      this.setIsAlreadyBroadcasting(false);
      this.setCurrentShowSessionId(undefined);
      this.setCurrentShowSessionStartTime(undefined);
      if (this.streamState === BroadcastStreamState.started) {
        if (reason != ShowStopReasons.ENTERED_CURTAIN_DOWN) {
          this.resetShowTippingSum();
        }

        if (
          ![
            ShowStopReasons.ENTERED_GOAL,
            ShowStopReasons.ENTERED_NUDE,
            ShowStopReasons.ENTERED_CURTAIN_DOWN,
            ShowStopReasons.ENTERED_PRIVATE,
            ShowStopReasons.LAST_USER_LEFT,
            ShowStopReasons.ENTERED_FREE,
            ShowStopReasons.ENTERED_GROUP,
            ShowStopReasons.ENTERED_ADMIN,
            ShowStopReasons.ENTERED_VERIFIER,
          ].includes(reason)
        ) {
          nodeChatStore.handleNotifications(
            message,
            BroadcastType.ShowStop,
            "showStopped"
          );

          if (
            reason === "BROADCAST_SESSION_STOPPED" &&
            this.streamState === BroadcastStreamState.started
          ) {
            if (broadcastStreamStore.streamType === BroadcastStreamType.OBS) {
              broadcastStreamStore.setBroadcastError({
                id: "broadcast-error.obs-disconnect",
                defaultMessage:
                  "Your OBS stream disconnected. If this is not intentional, your network may be having difficulties.",
              });
            } else {
              broadcastStreamStore.setBroadcastError({
                id: "broadcast-error.webrtc-disconnect",
                defaultMessage:
                  "Your stream disconnected, your network may be having difficulties or you may have accidentally disconnected your webcam/microphone.",
              });
            }
          }
          if (reason === ShowStopReasons.ADMIN_KICKED_STREAM) {
            broadcastStreamStore.setBroadcastError({
              id: "broadcast-error.kicked",
              defaultMessage:
                "Your broadcast has ended because an Admin has stopped your show.",
            });
            setTimeout(() => {
              window.close();
            }, 5000);
          }
          await broadcastStrategy.disconnect();
        }

        // Send model back to free show after the verifier show ended with member
        if (
          reason === ShowStopReasons.BROADCAST_SESSION_STOPPED &&
          message.showType === IShowType.VERIFIED
        ) {
          setTimeout(() => {
            this.startStreaming();
          });
        }

        if (message.showType === IShowType.ADMIN) {
          camsAudio.playEndPrivateChat();
          await this.resumeShowTypeBeforeAdmin();
        } else {
          this.previousShowType = message.showType;
        }
      }
    }
    this.log("handleShowStop finished");
  };

  handleViewStart = (message, properties) => {
    this.log("handleViewStart started", message, properties);
    if (properties.showType === IShowType.VOYEUR) {
      this.handleVoyeurStart(message, properties);
    }

    if (
      message.showType === IShowType.VERIFIER &&
      properties?.memberDisplayName
    ) {
      this.handleVerifierStart(message, properties);
    }

    const chatId = `${message.userName}@cams`.toLowerCase();
    if (nodeChatStore.allMemberHash[chatId]) {
      nodeChatStore.allMemberHash[chatId].currentShowType = message.showType;
      nodeChatStore.allMemberHash[chatId].currentShowStartTime =
        message.startTime;
      nodeChatStore.playMemberEnterSoundForMember(chatId);
    }

    this.log("handleViewStart finished");
  };

  handleViewStop = (message, properties, reason) => {
    this.log("handleViewStop started");
    if (properties.showType === IShowType.VOYEUR) {
      this.handleVoyeurStop(message, properties);
    }

    if (
      reason === "OUT_OF_CREDIT" &&
      [IShowType.GROUP, IShowType.NUDE, IShowType.PRIVATE].includes(
        message.showType
      )
    ) {
      nodeChatStore.addMemberNotification(
        {
          username: message.userName,
        },
        MemberActionEnum.LEFT,
        "due to running out of funds"
      );
    }
    const chatId = `${message.userName}@cams`.toLowerCase();
    if (
      nodeChatStore.allMemberHash[chatId] &&
      nodeChatStore.allMemberHash[chatId].currentShowStartTime &&
      nodeChatStore.allMemberHash[chatId].currentShowStartTime! <
        message.endTime
    ) {
      nodeChatStore.allMemberHash[chatId].currentShowType = IShowType.NONE;
      nodeChatStore.playMemberLeaveSoundForMember(chatId);
    }
    if (
      this.currentShowType === IShowType.PRIVATE ||
      this.currentShowType === IShowType.GROUP
    ) {
      nodeChatStore.checkRemainingMembers();
    }
    // if (nodeChatStore.allMemberHash[chatId] && nodeChatStore.allMemberHash[chatId].isReconnecting === true) {
    //   this.log("failed reconnecting started");

    //   const event = {
    //     id: uuid.getNew(),
    //     created: new Date(),
    //     fixedContentKey: `notificationMessage.viewFailedReconnection`,
    //     fixedContentParams: {
    //       member: `${data.userName}`,
    //     },
    //   } as IChatNotification;
    //   if(this.isInPaidShow){
    //     nodeChatStore.addNotification(event);
    //   }

    //   this.log("failed reconnecting finished");
    // }
    this.log("handleViewStop finished");
  };

  handleVerifierStart = (message, properties) => {
    this.log("handleVerifierStart started", message, properties);

    const memberName = properties?.memberDisplayName || message.userName;
    const memberNameWithSite = `${memberName}@cams`.toLowerCase();

    if (nodeChatStore.allMemberHash[memberNameWithSite]) {
      nodeChatStore.allMemberHash[memberNameWithSite].currentShowType =
        message.showType;
      nodeChatStore.allMemberHash[memberNameWithSite].currentShowStartTime =
        message.startTime;
      nodeChatStore.playMemberEnterSoundForMember(memberNameWithSite);
    }

    this.handleC2CStart({
      data: {
        payload: message,
        properties,
      },
    });

    this.log("handleVerifierStart finished");
  };

  handleVoyeurStart = (message, properties) => {
    this.log("handleVoyeurStart started");
    const memberName = properties?.memberDisplayName || message.userName;
    const memberNameWithSite = `${memberName}@cams`.toLowerCase();

    nodeChatStore.handleVoyeurMembersJoined({
      id: memberNameWithSite,
      username: memberNameWithSite,
      timeJoined: Date.now(),
      registration_date:
        nodeChatStore.allMemberHash[memberNameWithSite].registration_date,
    });

    const event = {
      id: uuid.getNew(),
      created: new Date(),
      fixedContentKey: `notificationMessage.memberJoinVoyeur`,
      fixedContentParams: {
        member: memberName,
      },
    } as IChatNotification;
    nodeChatStore.addNotification(event);

    this.log("handleVoyeurStart finished");
  };

  handleVoyeurStop = (message, properties) => {
    this.log("handleVoyeurStop started");
    const memberName = properties?.memberDisplayName || message.userName;
    nodeChatStore.handleVoyeurMembersLeft(message.userSiteId);

    const event = {
      id: uuid.getNew(),
      created: new Date(),
      fixedContentKey: `notificationMessage.memberLeftVoyeur`,
      fixedContentParams: {
        member: memberName,
      },
    } as IChatNotification;
    nodeChatStore.addNotification(event);

    this.log("handleVoyeurStop finished");
  };

  getTippingShowData = async () => {
    this.log("getTippingShowData started");
    try {
      //const profile = profileStore.modelProfile;
      // const { data } = await api.broadcastChat.get(
      //   `${profile.id}/tipping_session/`
      // );
      //this.handleTipped(data.data);
      this.log("getTippingShowData success");
    } catch (error) {
      this.log("getTippingShowData failed", error);
    } finally {
      this.log("getTippingShowData finished");
    }
  };

  handleTipped = message => {
    this.log("handleTipped started", message);
    if (message.all_member_tips) {
      const total = Object.values<any>(message.all_member_tips)
        .map(x => x.amount)
        .reduce((x, y) => x + y, 0);
      this.setShowTippingSum(total);
    }
    this.log("handleTipped finished");
  };

  showVideoSettingsPopover = () => {
    this.log("showVideoSettingsPopover started");
    this.isVideoSettingsPopoverShown = true;
    this.log("showVideoSettingsPopover finished");
  };

  showVideoSettingsOverlay = () => {
    this.log("showVideoSettingsOverlay started");
    this.isVideoSettingsOverlayShown = true;
    this.log("showVideoSettingsOverlay finished");
  };

  hideVideoSettingsOverlay = () => {
    this.log("hideVideoSettingsOverlay started");
    this.isVideoSettingsOverlayShown = false;
    this.log("hideVideoSettingsOverlay finished");
  };
  hideVideoSettingsPopover = () => {
    this.log("hideVideoSettingsPopover started");
    this.isVideoSettingsPopoverShown = false;
    this.log("hideVideoSettingsPopover finished");
  };

  showWheelOfFunSettingsPopover = () => {
    this.log("showWheelOfFunSettingsPopover started");
    this.isWheelOfFunSettingsPopoverShown = true;
    this.log("showWheelOfFunSettingsPopover finished");
  };

  hideWheelOfFunSettingsPopover = () => {
    this.log("hideWheelOfFunSettingsPopover started");
    this.isWheelOfFunSettingsPopoverShown = false;
    this.log("hideWheelOfFunSettingsPopover finished");
  };

  showAudioSettingsPopover = () => {
    this.log("showAudioSettingsPopover started");
    this.isAudioSettingsPopoverShown = true;
    this.log("showAudioSettingsPopover finished");
  };

  hideAudioSettingsPopover = () => {
    this.log("hideAudioSettingsPopover started");
    this.isAudioSettingsPopoverShown = false;
    this.log("hideAudioSettingsPopover finished");
  };

  startWebRTCShow = async () => {
    this.log("startWebRTCShow started");
    await broadcastStreamStore.startWebRTCBroadcast();
    this.log("startWebRTCShow finished");
  };

  setShowTippingSum = (tip: number): void => {
    this.log("setShowTippingSum started");
    this.showTippingSum = tip;
    this.log("setShowTippingSum finished");
  };

  resetShowTippingSum = (): void => {
    this.log("resetShowTippingSum started");
    this.showTippingSum = 0;
    this.log("resetShowTippingSum finished");
  };

  inviteToPrivate = (member: IMember) => {
    this.log("inviteToPrivate started", member);
    const event = {
      id: uuid.getNew(),
      created: new Date(),
      fixedContentKey: `notificationMessage.privateInvitation`,
      fixedContentParams: {
        member: member.username,
      },
    } as IChatNotification;
    nodeChatStore.addNotification(event);

    this.broadcastSocket?.emit("private_invitation", {
      member_id: member.member_id,
    });
    this.log("inviteToPrivate finished");
  };

  reqeustSubscribedMembers = () => {
    this.log("reqeustSubscribedMembers started");
    this.broadcastSocket?.emit("subscribed_members", {});
    this.log("reqeustSubscribedMembers finished");
  };

  handleC2CStart = event => {
    this.log("handleC2CStart started", event);
    const data = event.data;
    const memberId = data?.payload?.userSiteId;
    const memberName =
      data?.properties?.memberDisplayName || data?.payload?.userName;
    const chatHandle = `${memberName}@cams`.toLowerCase();
    nodeChatStore.addNotification({
      id: uuid.getNew(),
      created: new Date(),
      fixedContentKey: `notificationMessage.c2cStart`,
      fixedContentParams: {
        member: memberName,
      },
      isMessageOrNotification: "notification",
    });
    this.c2cHash = {
      ...this.c2cHash,
      [chatHandle]: {
        memberId,
        memberName,
      },
    };
    this.log("handleC2CStart finished");
  };

  handleMemberDestroy = (user: { id: string; username: string }) => {
    this.log("handleMemberDestroy received data", user);
  };

  handleC2CStop = event => {
    this.log("handleC2CStop started");
    const data = event.data;
    const memberName =
      data?.properties?.memberDisplayName || data?.payload?.userName;
    const chatHandle = `${memberName}@cams`.toLowerCase();
    nodeChatStore.addNotification({
      id: uuid.getNew(),
      created: new Date(),
      fixedContentKey: `notificationMessage.c2cEnd`,
      fixedContentParams: {
        member: memberName,
      },
      isMessageOrNotification: "notification",
    });
    if (!!this.c2cHash[chatHandle]) {
      const { [chatHandle]: _, ...newC2CHash } = this.c2cHash;
      this.c2cHash = newC2CHash;
    }
    this.log("handleC2CStop finished");
  };

  restoreFreeShow = async (): Promise<void> => {
    this.log("restoreFreeShow started");
    broadcastStreamStore?.stopNanoPlayer();
    await this.startShowType(IShowType.FREE);
    this.log("restoreFreeShow finished");
  };

  setPrivateShowChoice = (choice: PrivateShowSelection): void => {
    this.log("setPrivateShowChoice started");
    this.privateShowChoice = choice;
    this.log("setPrivateShowChoice finished");
  };

  setPrivateConsentOpen = (value: boolean): void => {
    this.log("setPrivateConsentOpen started");
    this.isPrivateConsentOpen = value;
    this.log("setPrivateConsentOpen finished");
  };

  setBountyOrdered = (member: string) => {
    this.log("setBountyOrdered started");
    this.bountyOrderMember = member;
    this.log("setBountyOrdered finished");
  };

  setFanClubOrdered = (member: string) => {
    this.log("setFanClubOrdered started");
    this.fanClubOrderMember = member;
    this.log("setFanClubOrdered finished");
  };

  handlePrivateShowRequest = data => {
    this.log("handlePrivateShowRequest started");
    const chatHandle = `${data.member_username}@cams`.toLowerCase();
    const isMemberOnline = !!nodeChatStore.allMemberHash[chatHandle]?.online;
    console.log("member found?", isMemberOnline);
    //camsAudio.playStartPrivateChat();

    // Only Opted in CS Agents/Model accounts using the member verification broadcaster should see VERIFIER invites
    if (
      data.show_type === "VERIFIER" &&
      this.broadcasterVariant !== "member-verification"
    ) {
      return;
    }

    // Normally member needs to be in free and online but verifier show drops the member offline to enter verified c2c so always allow invites for verifier
    if (
      (this.currentShowType === IShowType.FREE && isMemberOnline) ||
      data.show_type === "VERIFIER"
    ) {
      this.setPrivateConsentOpen(true);
      this.log(data);
      this.currentPrivateRequestRemainingSeconds = 60;
      this.currentPrivateRequestCountdownInterval = setInterval(() => {
        this.currentPrivateRequestRemainingSeconds -= 1;

        if (this.currentPrivateRequestRemainingSeconds === 1) {
          this.privateShowRequestAskForLater();
        }
      }, 1000);
      this.currentPrivateRequest = data;
      this.log("handlePrivateShowRequest finished");
    }
  };

  privateShowRequestAskForLater = async () => {
    this.log("privateShowRequestAskForLater started");
    await this.updatePrivateRequest("rejected");
    this.log("privateShowRequestAskForLater finished");
  };

  privateShowRequestDeclineForSession = async () => {
    this.log("privateShowRequestDeclineForSession started");
    await this.updatePrivateRequest("rejected_for_session");
    this.log("privateShowRequestDeclineForSession finished");
  };

  privateShowRequestAccept = async () => {
    this.log("privateShowRequestAccept started");
    await this.updatePrivateRequest("accepted");
    this.isCountUpTimerOn = false;
    this.isCountUpTimerOn = true;
    this.log("privateShowRequestAccept finished");
  };

  updatePrivateRequest = async status => {
    this.log("updatePrivateRequest started");
    try {
      await api.broadcastPrivateRequests.put(
        {
          status,
          show_type: this.currentPrivateRequest?.show_type,
        },
        `${this.currentPrivateRequest?.request_id}/`
      );
      this.log("updatePrivateRequest success");
    } catch (error) {
      this.log("updatePrivateRequest failed", error);
    } finally {
      this.setPrivateConsentOpen(false);
      if (this.currentPrivateRequestCountdownInterval) {
        clearInterval(this.currentPrivateRequestCountdownInterval);
      }
      this.currentPrivateRequest = null;
      this.log("updatePrivateRequest finished");
    }
  };

  get isCurtainDropped() {
    const _isCurtainDropped =
      this.currentShowType === IShowType.CURTAIN_DROPPED;
    this.log("isCurtainDropped returning", _isCurtainDropped);
    return _isCurtainDropped;
  }

  get isTippingShow() {
    const _isTippingShow = this.currentShowType === IShowType.TIPPING;
    this.log("isTippingShow returning", _isTippingShow);
    return _isTippingShow;
  }

  get isTippingShowStarted() {
    const _isTippingShowStarted = [
      IShowType.TIPPING,
      IShowType.CURTAIN_DROPPED,
    ].includes(this.currentShowType);
    this.log("isTippingShowStarted returning", _isTippingShowStarted);
    return _isTippingShowStarted;
  }

  get isFreeShow() {
    const _isFreeShow = this.currentShowType === IShowType.FREE;
    this.log("isFreeShow returning", _isFreeShow);
    return _isFreeShow;
  }

  get canStartNudeShow() {
    const _canStartNudeShow =
      this.currentShowType === IShowType.NUDE &&
      pricingStore.isNudePriceCorrect;
    this.log("canStartNudeShow returning", _canStartNudeShow);
    return _canStartNudeShow;
  }

  get isNudeShow() {
    const _isNudeShow = this.currentShowType === IShowType.NUDE;
    this.log("canStartNudeShow returning", _isNudeShow);
    return _isNudeShow;
  }

  get privateChatPrice() {
    let _privateChatPrice =
      this.currentPrivateRequest?.private_chat_price_tokens;
    if (
      this.currentPrivateRequest?.private_chat_price_tokens !==
      this.currentPrivateRequest?.private_chat_price_tokens_for_member
    ) {
      _privateChatPrice =
        this.currentPrivateRequest?.private_chat_price_tokens_for_member;
    }
    this.log("privateChatPrice returning", _privateChatPrice);
    return _privateChatPrice;
  }

  get partyChatPrice() {
    let _partyChatPrice = this.currentPrivateRequest?.party_chat_price_tokens;
    if (
      this.currentPrivateRequest?.party_chat_price_tokens !==
      this.currentPrivateRequest?.party_chat_price_tokens_for_member
    ) {
      _partyChatPrice =
        this.currentPrivateRequest?.party_chat_price_tokens_for_member;
    }
    this.log("partyChatPrice returning", _partyChatPrice);
    return _partyChatPrice;
  }

  setIsBuzzPopUpOpen = (bool: boolean) => {
    this.log("setIsBuzzPopUpOpen started");
    this.isBuzzPopUpOpen = bool;
    this.log("setIsBuzzPopUpOpen finished");
  };

  setIsWofPopUpOpen = (bool: boolean) => {
    this.log("setIsWofPopUpOpen started");
    this.isWofPopUpOpen = bool;
    this.log("setIsWofPopUpOpen finished");
  };

  get isPrivateChatEnabled() {
    const _isPrivateChatEnabled =
      !!pricingStore?.modelProducts?.is_private_allowed;
    this.log("isPrivateChatEnabled returning", _isPrivateChatEnabled);
    return _isPrivateChatEnabled;
  }

  setRecordingDurationInSeconds = (seconds: number) => {
    this.recordingDurationInSeconds = seconds;
  };

  addRecordingDurationInterval = () => {
    this.clearRecordingDurationInterval();
    this.recordingTimerInterval = setInterval(() => {
      this.setRecordingDurationInSeconds(this.recordingDurationInSeconds + 1);
      if (
        this.recordingDurationInSeconds ===
        BROADCAST_RECORDING_STOP_AFTER_N_SECONDS
      ) {
        this.stopRecording();
      }
    }, 1000) as unknown as number;
  };

  clearRecordingDurationInterval = () => {
    clearInterval(this.recordingTimerInterval);
    this.recordingTimerInterval = undefined;
    this.setRecordingDurationInSeconds(0);
  };

  startRecording = async (
    reason: RecordingReasonsEnum = RecordingReasonsEnum.ON_DEMAND
  ) => {
    this.log("startRecording started");
    const profile = profileStore.modelProfile;
    const channelName = (profile?.screen_name || "").toLowerCase();

    if (!this.isStartingRecording) {
      try {
        this.isStartingRecording = true;
        await api.broadcastRecorders.post(
          {
            show_type: this.currentShowType,
            is_manually_triggered: reason === RecordingReasonsEnum.ON_DEMAND,
          },
          `${channelName}/`
        );
        this.addRecordingDurationInterval();
        this.recordingReason = reason;
        this.isRecording = true;
        this.log("startRecording success");
      } catch (err) {
        this.clearRecordingDurationInterval();
        this.log("startRecording failed", err);
        snackbarStore.enqueueSnackbar({
          message: {
            id: "messages.error.recordFailed",
            default: "Record start failed, please try again.",
          },
          variant: SnackbarVariants.ERROR,
        });
        this.isRecording = false;
        throw new Error(err);
      } finally {
        this.isStartingRecording = false;
        this.log("startRecording finished");
      }
    }
  };

  stopRecording = async (notoast?: boolean) => {
    this.log("stopRecording started");
    const profile = profileStore.modelProfile;
    const channelName = (profile?.screen_name || "").toLowerCase();
    try {
      if (this.isRecording) {
        await api.broadcastRecorders.delete(`${channelName}/`);
        this.clearRecordingDurationInterval();
        this.isRecording = false;
        if (
          !notoast &&
          !(
            this.recordingReason === RecordingReasonsEnum.AUTOMATIC &&
            this.recordingDurationInSeconds <
              this.minimumRecordingDurationInSeconds
          )
        ) {
          snackbarStore.enqueueSnackbar({
            message: {
              id: "messages.success.recordedDone",
              default: "Saved video in 'Recorded Shows' album",
            },
            variant: SnackbarVariants.SUCCESS,
          });
        }
        this.log("stopRecording success, saved");
      }
    } catch (err) {
      this.log("stopRecording failed", err);
    } finally {
      this.log("stopRecording finished");
    }
  };

  onIsRetryingChanged = (isRetrying: boolean) => {
    if (isRetrying) {
      this.reconnectToastTO = setTimeout(() => {
        snackbarStore.enqueueSnackbar({
          message: {
            id: "notificationMessage.broadcastSocketRetrying",
            default: "Please wait, trying to reconnect to broadcast...",
          },
          variant: SnackbarVariants.INFO,
          options: {
            duration: Infinity,
          },
        });
      }, 30000);
    } else {
      if (
        snackbarStore.snackbars.includes(
          "notificationMessage.broadcastSocketRetrying"
        )
      ) {
        snackbarStore.dismissSnackbar(
          "notificationMessage.broadcastSocketRetrying"
        );
      }
      if (this.reconnectToastTO) {
        clearTimeout(this.reconnectToastTO);
      }
    }
  };

  resumeShowTypeBeforeAdmin = async () => {
    this.log(
      "resumeShowTypeBeforeAdmin started, previous show type:",
      this.previousShowType
    );
    let resumeShowType = this.previousShowType;
    if (this.previousShowType === IShowType.NONE) {
      return;
    } else if (this.previousShowType === IShowType.CURTAIN_DROPPED) {
      resumeShowType = IShowType.TIPPING;
    } else if (
      this.previousShowType === IShowType.PAUSED ||
      this.previousShowType === IShowType.ADMIN
    ) {
      resumeShowType = IShowType.FREE;
    }
    this.log(
      "resumeShowTypeBeforeAdmin starting show type, resume show type:",
      resumeShowType
    );
    await this.startShowType(resumeShowType);
    this.log("resumeShowTypeBeforeAdmin started show type, finished");
  };

  get shouldShowStreamingButton(): boolean {
    const _shouldShowStreamingButton =
      this.streamState === BroadcastStreamState.started &&
      this.currentShowType !== "ADMIN";
    this.log("shouldShowStreamingButton returning", _shouldShowStreamingButton);
    return _shouldShowStreamingButton;
  }

  setCountdownStarted = (bool: boolean) => {
    this.log("setCountdownStarted started");
    this.countdownStarted = bool;
    this.log("setCountdownStarted finished");
  };

  handleModelPrivateBlocked = data => {
    nodeChatStore.handleMemberPrivateBlocked(data.member_id);
  };

  setAllowPrivateForShowType = (showType: EnterShowSubType) => {
    if (
      this.isShowStarted &&
      [EnterShowSubType.TIPS, EnterShowSubType.PRIVATE].includes(showType)
    ) {
      pricingStore?.submitField(
        "is_private_allowed",
        showType === EnterShowSubType.PRIVATE
      );
    }
  };

  setCurrentShowTab = (tab: ShowTab) => {
    this.log("setCurrentShowTab started");
    this.previousShowTab = this.currentShowTab;
    this.currentShowTab = tab;
    this.log("setCurrentShowTab finished");
  };

  switchToShow = (tab: ShowTab, bypassTippingShowConfirmation?: boolean) => {
    this.log("switchToShow started");
    this.isCountUpTimerOn = false;
    if (this.shouldShowEndTippingShowConfirmation && this.isInTippingShow) {
      this.endTippingShow();
    }

    if (this.isTippingShowStarted && !bypassTippingShowConfirmation) {
      this.setShouldShowEndTippingShowConfirmation(true);
    } else {
      if (tab === ShowTab.FREE && this.currentShowType === IShowType.PAUSED) {
        this.restoreFreeShow();
        this.isCountUpTimerOn = false;
        this.isCountUpTimerOn = true;
      } else if (tab === ShowTab.FREE) {
        this.startShowType(IShowType.FREE);
      } else if (tab === ShowTab.NUDE) {
        this.startShowType(IShowType.NUDE);
      } else if (tab === ShowTab.TIPPING) {
        this.startShowType(IShowType.TIPPING);
      }
    }

    this.log("switchToShow finished");
  };

  setCurrentShowType = (showType: IShowType) => {
    this.log("setCurrentShowType started", showType);
    this.previousShowType = this.currentShowType;
    this.currentShowType = showType;
    this.log("setCurrentShowType finished");
  };

  setCurrentMainTab = (tab: MainTab) => {
    this.log("setCurrentMainTab started", tab);
    this.previousMainTab = this.currentMainTab;
    this.currentMainTab = tab;
    this.log("setCurrentMainTab finished");
  };

  endTippingShow = async () => {
    try {
      this.log("postQuickMessage started");
      await api.endTippingSession.post();
    } catch (error) {
      this.log("postQuickMessage failed", error);
    } finally {
      this.log("postQuickMessage finished");
    }
  };

  setWebRTCStatus = (status: WebRTCStatus) => {
    this.webRTCStatus = status;
  };

  fetchMemberShowSession = async (member: string) => {
    const session = await api.broadcastViewSessions.get(
      `${member}/${profileStore.modelProfile?.username.toLowerCase()}`
    );
    return session;
  };

  verifyMemberAge = async (
    user_id: string,
    sex: number,
    age_range: string,
    is_photo_verified: boolean,
    country: string,
    bday_day: string,
    bday_month: string,
    bday_year: string,
    state: string
  ): Promise<boolean> => {
    try {
      this.log("verifyMemberAge started", user_id);
      const { data } = await api.modelVerifyAge.post({
        user: user_id,
        sex,
        age_range,
        is_photo_verified,
        country: country,
        bday_day,
        bday_month,
        bday_year,
        ...(country === "US" && { state }),
      });

      if (data.success) {
        snackbarStore.enqueueSnackbar({
          message: {
            id: "messages.error.verifyAgeSucess",
            default: "Age verification submitted",
          },
          variant: SnackbarVariants.SUCCESS,
        });

        return true;
      } else {
        snackbarStore.enqueueSnackbar({
          message: {
            id: "messages.error.verifyAgeFailed",
            default: "Age verification failed, please try again.",
          },
          variant: SnackbarVariants.ERROR,
        });

        return false;
      }
    } catch {
      snackbarStore.enqueueSnackbar({
        message: {
          id: "messages.error.verifyAgeFailed",
          default: "Age verification failed, please try again.",
        },
        variant: SnackbarVariants.ERROR,
      });

      return false;
    }
  };

  denyMemberAgeVerification = async (user_id: string) => {
    try {
      const { data } = await api.suspendAgeVerificationMember.post({
        user: user_id,
        is_snoozed_permanently: true,
        snoozed_reason: "Denied by Agent",
      });
      if (data.success) {
        snackbarStore.enqueueSnackbar({
          message: {
            id: "messages.error.verifyAgeDenySuccess",
            default: "Denied member",
          },
          variant: SnackbarVariants.SUCCESS,
        });

        return true;
      } else {
        snackbarStore.enqueueSnackbar({
          message: {
            id: "messages.error.verifyAgeDenyFailed",
            default: "Deny failed, please try again.",
          },
          variant: SnackbarVariants.ERROR,
        });

        return false;
      }
    } catch {
      snackbarStore.enqueueSnackbar({
        message: {
          id: "messages.error.verifyAgeDenyFailed",
          default: "Deny failed, please try again.",
        },
        variant: SnackbarVariants.ERROR,
      });
      return false;
    }
  };

  suspendMemberAgeVerification = async (user_id: string) => {
    try {
      const futureDate = new Date();
      futureDate.setDate(futureDate.getDate() + 10);
      const { data } = await api.suspendAgeVerificationMember.post({
        user: user_id,
        snoozed_until: futureDate.toISOString(),
        snoozed_reason: "Snoozed by Agent",
      });

      if (data.success) {
        snackbarStore.enqueueSnackbar({
          message: {
            id: "messages.error.verifyAgeSnoozeSuccess",
            default: "Snooze successful",
          },
          variant: SnackbarVariants.SUCCESS,
        });

        return true;
      } else {
        snackbarStore.enqueueSnackbar({
          message: {
            id: "messages.error.verifyAgeSnoozeFailed",
            default: "Snooze failed, please try again.",
          },
          variant: SnackbarVariants.ERROR,
        });

        return false;
      }
    } catch {
      snackbarStore.enqueueSnackbar({
        message: {
          id: "messages.error.verifyAgeSnoozeFailed",
          default: "Snooze failed, please try again.",
        },
        variant: SnackbarVariants.ERROR,
      });
      return false;
    }
  };
}
