import TailwindFlex from "library/components/_tailwind/flex";
import TailwindText from "library/components/_tailwind/text";
import TailwindButton from "library/components/_tailwind/button";
import TailwindDropdown from "library/components/_tailwind/dropdown";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import AuthStore from "core/stores/auth/AuthStore";
import { inject, observer } from "mobx-react";
import TailwindInputBase, {
  TailwindInputType,
} from "library/components/_tailwind/input-base";
import BroadcastMemberVerifyViewer from "../broadcast-preview/broadcast-member-verify-viewer";
import NodeChatStore from "../_stores/nodechat/NodeChatStore";
import BroadcastStore from "../_stores/broadcast/BroadcastStore";
import { US_STATES } from "library/core/constants";
import { ShowTab } from "../_stores/broadcast/enums";

type Props = {
  authStore?: AuthStore;
  nodeChatStore?: NodeChatStore;
  broadcastStore?: BroadcastStore;
};

type CountryItemValues = {
  label: string;
  value: string;
};

const GENDER_OPTIONS = [
  { key: "0", value: "Unknown" },
  { key: "1", value: "Male" },
  { key: "2", value: "Female" },
  { key: "3", value: "Couple" },
  { key: "4", value: "Group" },
  { key: "5", value: "Couple (F/F)" },
  { key: "6", value: "Couple (M/M)" },
  { key: "7", value: "Trans" },
];
type Gender = typeof GENDER_OPTIONS[number]["key"];

const AGE_GROUP_OPTIONS = [
  { key: "0", value: "Unknown" },
  { key: "18/19", value: "18/19" },
  { key: "20s", value: "20s" },
  { key: "30s", value: "30s" },
  { key: "40s", value: "40s" },
  { key: "50s", value: "50s" },
  { key: "60+", value: "60+" },
];
type AgeGroup = typeof AGE_GROUP_OPTIONS[number]["key"];

const BroadcastMemberVerifyPanel: React.ComponentType<Props> = ({
  authStore,
  nodeChatStore,
  broadcastStore,
}) => {
  const {
    c2cHash,
    verifyMemberAge,
    suspendMemberAgeVerification,
    denyMemberAgeVerification,
    switchToShow,
  } = broadcastStore!;
  const { members, getMemberDetails } = nodeChatStore!;
  const { countries } = authStore!;
  const [gender, setGender] = useState<Gender>("0");
  const [ageGroup, setAgeGroup] = useState<AgeGroup>("0");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [country, setCountry] = useState("US");
  const [state, setState] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isVerifyButtonDisabled, setIsVerifyButtonDisabled] = useState(false);
  const member = members[0]; // VERIFIER show is 1 on 1 show so there should only be one member in the member list
  const [photoUrl, setPhotoUrl] = useState("");

  const countryDropdownValues = useMemo(() => {
    const countryValues = Array<CountryItemValues>();
    for (const [isoCode, countryName] of Object.entries(countries)) {
      countryValues.push({
        label: countryName,
        value: isoCode,
      });
    }
    return countryValues;
  }, [countries]);

  const stateDropdownValues = useMemo(() => {
    const stateValues = Array<CountryItemValues>();
    for (const [isoCode, name] of Object.entries(US_STATES)) {
      stateValues.push({
        label: name,
        value: isoCode,
      });
    }
    return stateValues;
  }, [countries]);

  const isC2CActive = useMemo(() => {
    return !!Object.values(broadcastStore?.c2cHash || {}).length;
  }, [c2cHash]);

  const deny = async () => {
    if (member?.user_id) {
      setIsVerifyButtonDisabled(true);
      const success = await denyMemberAgeVerification(member.user_id);
      if (!success) {
        setIsVerifyButtonDisabled(false);
      }
    } else {
      endShow();
    }
  };

  const snooze = async () => {
    if (member?.user_id) {
      setIsVerifyButtonDisabled(true);
      const success = await suspendMemberAgeVerification(member.user_id);
      if (!success) {
        setIsVerifyButtonDisabled(false);
      }
    } else {
      endShow();
    }
  };

  const submit = async () => {
    if (!member?.user_id) {
      return;
    }

    setIsVerifyButtonDisabled(true);
    const success = await verifyMemberAge(
      member.user_id,
      Number(gender),
      ageGroup,
      isChecked,
      country,
      day,
      month,
      year,
      state
    );

    if (!success) {
      setIsVerifyButtonDisabled(false);
    }
  };

  const endShow = () => {
    switchToShow(ShowTab.FREE);
  };

  const initMember = useCallback(async () => {
    if (member) {
      const data = await getMemberDetails(member);

      if (data?.profile_photo) {
        setPhotoUrl(data?.profile_photo);
      }
      if (data?.sex) {
        setGender(`${data?.sex}`);
      }

      if (data?.age && data?.age < 18) {
        setAgeGroup(AGE_GROUP_OPTIONS[0].key); // Unknown
      } else if (data?.age && data?.age < 20) {
        setAgeGroup(AGE_GROUP_OPTIONS[1].key); // 18/19
      } else if (data?.age && data?.age < 30) {
        setAgeGroup(AGE_GROUP_OPTIONS[2].key); // 20s
      } else if (data?.age && data?.age < 40) {
        setAgeGroup(AGE_GROUP_OPTIONS[3].key); //30s
      } else if (data?.age && data?.age < 50) {
        setAgeGroup(AGE_GROUP_OPTIONS[4].key); //40s
      } else if (data?.age && data?.age < 60) {
        setAgeGroup(AGE_GROUP_OPTIONS[5].key); //50s
      } else {
        setAgeGroup(AGE_GROUP_OPTIONS[6].key); //60s
      }

      if (data?.country_code) {
        setCountry(data?.country_code);
      }

      if (data?.state_code) {
        setState(`US-${data?.state_code}`);
      }

      if (data?.birthdate) {
        setYear(`${data.birthdate[0]}`);
        setMonth(`${data.birthdate[1]}`);
        setDay(`${data.birthdate[2]}`);
      }
    }
  }, [member]);

  useEffect(() => {
    initMember();
  }, [member]);

  return (
    <TailwindFlex flexDirection='flex-row'>
      {isC2CActive && (
        <TailwindFlex width='w-1/2'>
          <TailwindFlex
            backgroundColor='bg-gray-200'
            margin={["m-4"]}
            borderRadius={"rounded-lg"}>
            <BroadcastMemberVerifyViewer />
          </TailwindFlex>
        </TailwindFlex>
      )}
      <TailwindFlex
        margin={["m-4"]}
        flexDirection='flex-col'
        gap='gap-2'
        width={isC2CActive ? "w-1/2" : "w-full"}>
        <TailwindFlex flexDirection='flex-col'>
          <TailwindText fontWeight='font-semibold'>Gender</TailwindText>
          <TailwindFlex gap='gap-2' flexWrap='flex-wrap'>
            {GENDER_OPTIONS.map(({ key, value }) => (
              <button
                key={key}
                className={`px-3 py-1 rounded ${
                  gender === key ? "bg-black text-white" : "bg-white text-black"
                }`}
                onClick={() => setGender(key)}>
                {value}
              </button>
            ))}
          </TailwindFlex>
        </TailwindFlex>

        <TailwindFlex flexDirection='flex-col'>
          <TailwindText fontWeight='font-semibold'>Age Range</TailwindText>
          <TailwindFlex gap='gap-2' flexWrap='flex-wrap'>
            {AGE_GROUP_OPTIONS.map(({ key, value }) => (
              <button
                key={key}
                className={`px-3 py-1 rounded ${
                  ageGroup === key
                    ? "bg-black text-white"
                    : "bg-white text-black"
                }`}
                onClick={() => setAgeGroup(key)}>
                {value}
              </button>
            ))}
          </TailwindFlex>
        </TailwindFlex>

        <TailwindFlex flexDirection='flex-col'>
          <TailwindText fontWeight='font-semibold'>Birth Date</TailwindText>
          <TailwindFlex space='space-x-2'>
            <input
              type='text'
              placeholder='MM'
              className='w-16 p-2 border rounded'
              value={month}
              onChange={e => setMonth(e.target.value)}
            />
            <input
              type='text'
              placeholder='DD'
              className='w-16 p-2 border rounded'
              value={day}
              onChange={e => setDay(e.target.value)}
            />
            <input
              type='text'
              placeholder='YYYY'
              className='w-20 p-2 border rounded'
              value={year}
              onChange={e => setYear(e.target.value)}
            />
          </TailwindFlex>
        </TailwindFlex>

        <TailwindFlex gap='gap-4'>
          <TailwindDropdown
            width={"w-full"}
            focusBorderColor={"md:border-black"}
            backgroundColor={"bg-white"}
            // selectedItemButtonProps={{
            //   backgroundColor: "bg-transparent",
            // }}
            items={countryDropdownValues || []}
            borderColor={"border-black"}
            borderWidth={["border"]}
            fullWidth
            label={"Country"}
            value={country}
            name={"country"}
            onChange={value => {
              setCountry(`${value}`);
            }}
            onClick={e => e.preventDefault()}
          />
          <TailwindDropdown
            disabled={country !== "US"}
            width={"w-full"}
            focusBorderColor={"md:border-black"}
            backgroundColor={"bg-white"}
            items={stateDropdownValues || []}
            borderColor={"border-black"}
            borderWidth={["border"]}
            fullWidth
            label={"State"}
            value={state}
            name={"state"}
            onChange={value => {
              setState(`${value}`);
            }}
            onClick={e => e.preventDefault()}
          />
        </TailwindFlex>

        {photoUrl && (
          <TailwindFlex
            alignItems='items-center'
            justifyContent='justify-start'
            gap='gap-5'>
            <TailwindFlex
              width='w-32'
              height='h-32'
              borderWidth={["border-2"]}
              borderColor='border-gray-300'
              borderRadius={"rounded"}>
              <img
                src={photoUrl}
                alt='User'
                className='w-full h-full object-cover'
              />
            </TailwindFlex>
            <TailwindInputBase
              name={"checkbox"}
              type={TailwindInputType.checkbox}
              cursor={"cursor-pointer"}
              width={"w-8"}
              height={"h-8"}
              psuedoClasses={["focus:outline-none"]}
              onChange={() => setIsChecked(!isChecked)}
              checked={isChecked}
            />
          </TailwindFlex>
        )}

        <TailwindFlex gap='gap-4'>
          <TailwindButton
            fullWidth={false}
            width='w-max'
            cursor='cursor-pointer'
            padding={["px-10"]}
            backgroundColor={"bg-black"}
            borderRadius={"rounded-lg"}
            textColor='text-white'
            display={"flex"}
            alignItems={"items-center"}
            justifyContent={"justify-center"}
            onClick={deny}
            disabled={isVerifyButtonDisabled}>
            Close
          </TailwindButton>

          <TailwindButton
            backgroundColor={"bg-black"}
            borderRadius={"rounded-lg"}
            cursor='cursor-pointer'
            textColor='text-white'
            display={"flex"}
            alignItems={"items-center"}
            justifyContent={"justify-center"}
            onClick={snooze}
            disabled={isVerifyButtonDisabled}>
            Snooze
          </TailwindButton>

          <TailwindButton
            backgroundColor='bg-pink-500'
            textColor='text-white'
            cursor='cursor-pointer'
            borderRadius={"rounded-lg"}
            justifyContent='justify-center'
            onClick={submit}
            disabled={isVerifyButtonDisabled}>
            Verify Member
          </TailwindButton>
        </TailwindFlex>
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default inject(
  "authStore",
  "nodeChatStore",
  "broadcastStore"
)(observer(BroadcastMemberVerifyPanel));
